.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 40px;
}

.wrapper {
  text-align: center;
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
}

.lds-dual-ring:after {
  content: ' ';
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #20314d;
  border-color: #20314d transparent #20314d transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}